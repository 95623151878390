import React from 'react';
import PropTypes from 'prop-types';
import { getJoinedChallengesByUser } from '../../redux/actions';
import { connect } from 'react-redux';
import { isNull, isUndefined, isEmpty } from 'lodash';
import { ImageUrl } from '../../utils/constants';

import { ChallengeCard, TopSection, MiddleSection, BottomSection, IconContainer } from './styles';

class ChallengesCard extends React.Component {

  componentDidMount(){
    const { getJoinedChallengesByUser } = this.props;
    getJoinedChallengesByUser();
  }
  render() {
    const {challengesList} = this.props;
    return(
      <div>
        {isEmpty(challengesList) || isNull(challengesList) || isUndefined(challengesList)
          ? '' :
          <ChallengeCard>
            <TopSection>
              <div>Upcoming Challenges</div>
              <div>View All</div>
            </TopSection>
            <MiddleSection>
              <div>
                <div><img src='/public/images/social/imageIcon.png' /></div>
                <div>
                  <div>UPCOMING COMPANY CHALLENGE</div>
                  <div>7-Day Sugar Detox Challenge</div>
                  <div>
                    <div>
                      <div/>
                    </div>
                    <div>30%</div>
                  </div>
                </div>
                <div><img src='/public/images/social/rightShift.png' /></div>
              </div>
            </MiddleSection>
            <BottomSection>
              <div>
                <IconContainer>
                  {challengesList && challengesList.length > 0 ? challengesList.slice(0,2).map((user, index) => (
                    <span key={index}>
                      {user? <img src={`${ImageUrl}/${user.user_list[0].profile_image}`} /> : <div/>}
                    </span>
                  )) : null}
                  <span className="circle">{challengesList && challengesList.length > 0 && challengesList.length - 4}+</span>
                </IconContainer>
                <div>5 Day To Start | 100 Points</div>
              </div>
              <div>Feb 16, 2021</div>
            </BottomSection>
          </ChallengeCard>
        }
      </div>
    );
  }
}

ChallengesCard.propTypes = {
  challengesList: PropTypes.array,
  getJoinedChallengesByUser: PropTypes.func
};

const mapStateToProps = (state) => ({
  challengesList: state.social.challengesList,
})
const mapDispatchToProps = (dispatch) => ({
  getJoinedChallengesByUser: () => dispatch(getJoinedChallengesByUser())
})

export default connect(mapStateToProps, mapDispatchToProps)(ChallengesCard);