import React, {Component} from 'react';
import PropTypes from 'prop-types';
import isEmpty from "lodash/isEmpty";
import UserCard from '../UserCardV2';
import {ChampionTab, CardContainer, ChampionsCard} from "./styles";
class ChampionSectionV2 extends Component {

  showUsersCard = (WellnessChampionsData) => (
    WellnessChampionsData.map((data, index) => (
      <ChampionsCard key={index} margin={index >0 && (index === 3 || index === 7 ||index === 11 || index === 15) ||index === 19||index === 23||index === 27? '0px 0px 15px 0':'0px 15px 15px 0'}>
        <div>
          <UserCard data={data}/>
        </div>
      </ChampionsCard>
    ))
  );

  WellnessChampionsDashboard = (WellnessChampionsData) => (
    <div>
      {isEmpty(WellnessChampionsData) ?
        <CardContainer style={{marginTop:"35px"}}>
          <h3>No wellness champions found</h3>
        </CardContainer> : null}
      <CardContainer style={{marginTop:"35px"}}>
        {isEmpty(WellnessChampionsData) ? '' : this.showUsersCard(WellnessChampionsData)}
      </CardContainer>
    </div>
  );

  render() {
    const { wellnessChampions } = this.props;
    return(
      <ChampionTab style={{width:"1246px",margin:"auto"}}>
        { this.WellnessChampionsDashboard(wellnessChampions)}
      </ChampionTab>
    );
  }
}

ChampionSectionV2.propTypes = {
  wellnessChampions: PropTypes.array,
};

export default ChampionSectionV2;