import styled from 'styled-components';

const BoldFont = 'Rubik-Medium';
const RegularFont = 'Rubik-Regular';

const ChampionTab = styled.div`
  height: auto;
  @media (max-width: 500px) {
    padding: 20px 10px;
  }
`;

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  float: left;
  font-size: 12px;
  text-transform: uppercase;
  text-align: left;
  position: relative;
  bottom: 12px;
  h3 {
    font-family: ${RegularFont};
    font-size: 20px;
    color: #000;
    padding-left: 5px;
    text-transform: capitalize;
  }
`;

const ChampionsCard = styled.div`
  display: inline-block;
  width: calc(100%/4);
  position: relative;
  float: left;
  @media (max-width: 1200px) {
    width: calc(100%/3);
  }
  @media(max-width: 760px) {
    width: calc(100%/2);
    float: left;
  } 
  @media(max-width: 500px) {
    width: 100%;
  }
  > div {
    height: 100%;
    padding: 10px 12px;
    padding: ${({margin }) => margin ? margin : '0px 0px 15px 0'};
  }
`;
const WellnessChampionsHeader = styled.div`
  font-family: ${BoldFont};
  float: left;
  font-size: 14px;
  color: #FFFFFF;
  text-transform: uppercase;
  width: 100%;
  padding: 13px 0 13px 13px;
  background-color: #1C4667;
  border-radius: 6px 6px 6px 6px
  
  @media (max-width: 760px) {
    text-align: left;
  }
`;

export { ChampionTab, CardContainer , ChampionsCard, WellnessChampionsHeader}